
import './App.css';
import { Home } from './Component/Home'
import React from 'react';
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
