import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import { HeaderLayOut } from "../LayOut/HeaderLayOut";
import { FooterLayOut } from "../LayOut/FooterLayOut";
//import axios from "../api/axios";
import axios from "axios";
export const Home = () => {
  const TockenURL = "https://identity.crea.ca/connect/token";
  useEffect(() => {
    // alert(localStorage.getItem('companyId'));

    const FetchPropertyetails = async () => {
      const response = await axios.post(
        TockenURL,
        JSON.stringify({
          grant_type: "client_credentials",
          client_id: "G2xjOZC1vke5VcffoL8MqDBj",
          client_secret: "mjAOTxCxzdcK6bvZPiR8xGz2",
          scope: "DDFApi_Read",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: false,
        }
      );
      console.log(response.data);
    };
    FetchPropertyetails();
  }, []);

  return (
    <>
      <HeaderLayOut></HeaderLayOut>
      <div>Body</div>
      <FooterLayOut></FooterLayOut>
    </>
  );
};
